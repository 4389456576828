import { render, staticRenderFns } from "./SolidWaste.vue?vue&type=template&id=4d3f7f4e&"
import script from "./SolidWaste.vue?vue&type=script&setup=true&lang=js&"
export * from "./SolidWaste.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports